.room-edit {
  &__content {
    margin-top: 38px;
  }

  &__data {
    display: grid;
    column-gap: 32px;
    row-gap: 24px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "center room-type"
      "name   floor"
      "status status"
      "note   note";
  }

  &__center {
    grid-area: center;
  }

  &__room-type {
    grid-area: room-type;
  }

  &__name {
    grid-area: name;
  }

  &__status {
    grid-area: status;
  }

  &__floor {
    grid-area: floor;
  }

  &__note {
    position: relative;
    grid-area: note;
    &__count {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 32px;
  }
}
