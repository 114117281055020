.room-info {
  &__content {
    margin-top: 42px;
    background-color: #f4f4f4;
    padding: 32px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 42px;
    > .bx--btn {
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
