@import "carbon-components/scss/globals/scss/styles.scss";
@import "carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss";
@import "carbon-components/scss/globals/scss/typography.scss";
@import "carbon-components/scss/globals/scss/vars.scss";

.App {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    flex: 1 0 auto;
    padding-top: 47px;
  }

  &__footer {
    background: #000;
    color: #fff;
    padding: 16px;
    text-align: center;
  }

  &__commit_version {
    color: #000;
  }
}
